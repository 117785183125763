import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExams(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/exams', { roleid: 7 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchExamDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getinstructordetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    updateExamStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructerstatus', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    updateInstructorphoto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructorphoto', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createExam(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/createexam',   userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteExam(ctx, userData) {

      //console.log("course Delete triggered");;
      //console.log(userData);
     

      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/deleteexam',   userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    statusExam(ctx, userData) {

      //console.log("course Delete triggered");;
      //console.log(userData);
     

      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/statusexam',   userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
