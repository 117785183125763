<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Exam
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleQuestionSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Exam Title"
            rules="required"
          >
            <b-form-group
              label="Exam Title"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="localExam.title"
                autofocus
                 
                trim
                placeholder="New Quiz"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
       
       <validation-provider
            #default="validationContext"
            name="examType"
            rules="required"
          >
           
            <b-form-group
              label="Exam Type"
              label-for="username"
            >
               <v-select
          v-model="localExam.examType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="typeoptions"
        />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
            <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="username"
            >
              <b-form-textarea
                id="username"
                v-model="localExam.description"
               
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
<b-row>
  <b-col md="12">

  <validation-provider
            #default="validationContext"
            name="Duration"
            rules="required"
          >
            <b-form-group
              label="Duration"
              label-for="username"
            >
              
              <b-form-checkbox
      v-model="localExam.hasExactOccurDate"
      value="A"
      class="custom-control-primary"
    >
      Exam has exact occur date
    </b-form-checkbox>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
</b-col>
</b-row>

<b-row v-if="localExam.hasExactOccurDate"> 
  <b-col md="7">

  <validation-provider
            #default="validationContext"
            name="Duration"
            
          >
            <b-form-group
              label="Start Date"
              label-for="username"
            >
              
            <b-form-datepicker
      id="datepicker-invalid"
      model="localExam.examStartDate"
      :state="localExam.hasExactOccurDate&&(localExam.examStartDate!=null||localExam.examStartDate!='')"
      class="mb-2"
    />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
</b-col>
  <b-col md="5">

  <validation-provider
            #default="validationContext"
            name="Duration"
        
          >
            <b-form-group
              label="Time"
              label-for="username"
            >
              
           <b-form-timepicker
      id="datepicker-time"
          model="localExam.examStartTime"
      :state="localExam.hasExactOccurDate&&(localExam.examStartTime!=null||localExam.examStartTime!='')"
      class="mb-2"
    />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
</b-col>
</b-row>
<b-row v-if="localExam.hasExactOccurDate"> 
  <b-col md="7">

  
</b-col>
  <b-col md="5">

  <validation-provider
            #default="validationContext"
            name="Duration"
        
          >
            <b-form-group
              label="Time"
              label-for="username"
            >
              
           <b-form-timepicker
      id="datepicker-timeend"
         model="localExam.examEndTime"
      :state="localExam.hasExactOccurDate&&(localExam.examEndTime!=null||localExam.examEndTime!='')"
      class="mb-2"
    />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
</b-col>
</b-row>


 <b-row>
  <b-col md="4">

  <validation-provider
            #default="validationContext"
            name="Duration"
            rules="required"
          >
            <b-form-group
              label="Duration"
              label-for="username"
            >
              
             <b-form-input
                id="email"
                v-model="localExam.examDuration"
                placeholder="Duration in minutes"
            
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
</b-col>
<b-col md="4">
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="passLevel"
            rules="required"
          >
            <b-form-group
              label="Exam Pass Level"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="localExam.passLevel"
               
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </b-col>

          <b-col md="4">
               <validation-provider
            #default="validationContext"
            name="Max Attempt Count"
            rules="required"
          >
            <b-form-group
              label="Max Attempt"
              label-for="maxAttemptCount"
            >
              <b-form-input
                id="maxAttemptCount"
                v-model="localExam.maxAttemptCount"
                
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          </b-col>
 </b-row>
          <!-- Company -->
      <div v-if="localExam.examType==='INPERSON'">
     

            <validation-provider
            #default="validationContext"
            name="examLocation"
            rules="required"
          >
            <b-form-group
              label="Exam Location"
              label-for="examLocation"
            >
              <b-form-input
                id="examLocation"
                v-model="localExam.examLocation"
               
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
 <b-row>
  <b-col md="6">

   <validation-provider
            #default="validationContext"
            name="examLocationRoom"
            rules="required"
          >
            <b-form-group
              label="Room"
              label-for="examLocationRoom"
            >
              <b-form-input
                id="examLocationRoom"
                v-model="localExam.examLocationRoom"
               
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          </b-col>
          <b-col md="6">
             <validation-provider
            #default="validationContext"
            name="examLocationSeat"
            rules="required"
          >
            <b-form-group
              label="Seat"
              label-for="examLocationSeat"
            >
              <b-form-input
                id="examLocationSeat"
                v-model="localExam.examLocationSeat"
              
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

  </b-col>
  </b-row>

   </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
   BButton,BFormTextarea,BRow,BCol,BFormCheckbox,BFormTimepicker,BFormDatepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BRow,BCol,BFormCheckbox,BFormTimepicker,BFormDatepicker,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  methods:{

    resetForm(){

    },
      generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
},
    repeateAgain() {

     

      
      this.localQuestion.questionAnswers.push({
       
        selected: false,
        value:this.generateUUID(),
        answer: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
       var items=this.localQuestion.questionAnswers;

//        var result = items.filter(obj => {
//   return obj.value=== item
// })
 

       items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
        handleQuestionSubmit() {
        //console.log("Exam ADD Pquestion  Create triggered");

 
     
      //console.log(this.localExam);
       

      store.dispatch('apps-exam/createExam', this.localExam)
        .then(() => {
          
        this.selectedCategory=null;
        this.localExam={
          id:null,
        hasExactOccurDate:false,
        title:'',
        examType:"ONLINE",
        description:'',
        examDuration:120,
        passLevel:70,
        maxAttemptCount:0,
        examLocation:'',
        examLocationRoom:'',
        examLocationSeat:'',
            };


         this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        })
    },
      SurveyTypeUpdated(payload) {
      //console.log("question result in info ");
      //console.log(payload);
      if (payload==null)
      {
  
      this.questionTypeKey=null;
     this.questionType=null;
          this.localQuestion.questionType = null,
    this.localQuestion.questionTypeKey = null
      } else
    {  
    //console.log("question type  data updated"+payload.typekey);

     this.questionTypeKey=payload.typekey;
     this.questionType=payload.title;
      this.selectedCategory=payload;

       this.localQuestion.questionType = payload.title,
     this.localQuestion.questionTypeKey = payload.typekey
    }

    },
     
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
        activeExam:null
  },
   watch: {
   
    activeExam(newVal,oldVal){
      if(newVal){
        this.localExam=newVal;
        //console.log("exam updated");
        //console.log(this.localExam);
     
       
      }
    }
    },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      localExam:{
        hasExactOccurDate:false,
        title:'',
        examType:"ONLINE",
        description:'',
        examDuration:120,
        passLevel:70,
        maxAttemptCount:0,
        examLocation:'',
        examLocationRoom:'',
        examLocationSeat:'',




      },
      typeoptions:[
     "ONLINE" ,
      "INPERSON" 
      ]
    }
  },
  setup(props, { emit }) {

    return;
    const blankUserData = {
      title: '',
      description: '',
      examType: 'ONLINE',
      description:'',
      examDuration: '',
  examLocation:'',
  examLocationRoom:'',
  examLocationSeat:'',
  maxAttemptCount:0,
  passLevel:70,
  hasExactOccurDate:false,
  examStartDate:null,
examEndDate:null,
additionalInfo:null

    }

    const localExam = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      localExam.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('apps-exam/createExam', localExam.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      localExam,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
