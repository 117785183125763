<template>

  <div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" :activeExam="selectedExam" @refetch-data="refetchData" />



    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="newExam">
                <span class="text-nowrap">Create New Exam</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refUserListTable" class="position-relative" :filter="searchQuery" :items="items" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc" :per-page="perPage" :current-page="currentPage">

        <!-- Column: User -->
        <template #cell(title)="data">
          <b-media vertical-align="center">

            <b-button variant="flat-primary" @click="editExam(data.item)">
              {{ data.item.title }}
            </b-button>


          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ resolveUserStatusVariantText(data.item.status) }}

          </b-badge>
        </template>
        <template #cell(questions)="data">

          <b-link :to="{ name: 'exam-questions', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap">

            Questions
          </b-link>

        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">


          <b-badge variant="light-danger" v-if="Boolean(data.item.deleted)">


            Deleted
          </b-badge>

          <b-dropdown v-if="!Boolean(data.item.deleted)" :right="$store.state.appConfig.isRTL">

            <template #button-content>

              Actions


            </template>

            <b-dropdown-item>
              <b-link variant="flat-info" @click="editExam(data.item)">

                <feather-icon icon="Edit3Icon" />
                <span class="align-middle ml-50">Edit</span>
              </b-link>
            </b-dropdown-item>



            <b-dropdown-item>
              <b-link variant="flat-danger" @click="deleteExam(data.item)">

                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-link>

            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './ExamsListFilters.vue'
import useUsersList from './useExamsList'
import userStoreModule from '../examStoreModule'
import UserListAddNew from './ExamListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  computed: {

  },
  data() {
    return {
      selectedExam: null
    }
  },
  methods: {
    generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();//Timestamp
      var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      })
    },
    newExam() {
      this.isAddNewUserSidebarActive = true;
      this.selectedExam = null;
      this.selectedExam = {
        id: null,
        hasExactOccurDate: false,
        title: '',
        examType: "ONLINE",
        description: '',
        examDuration: 120,
        passLevel: 70,
        maxAttemptCount: 0,
        examLocation: '',
        examLocationRoom: '',
        examLocationSeat: '',
      };


    },
    editExam(question) {
      //console.log(question);
      this.isAddNewUserSidebarActive = true;
      this.selectedExam = null;

      this.selectedExam = question;
      //console.log("selected question", this.selectedExam);
    },
    getRoute(coursemode) {

      return "page-coursemode";
    }
    ,
    deleteExam(coursedata) {
      //console.log(coursedata);

      store.dispatch('apps-exam/deleteExam', coursedata)
        .then((response) => {

          var result = response.data.success;


          this.selectedCategory = null;
          this.localExam = {
            id: null,
            hasExactOccurDate: false,
            title: '',
            examType: "ONLINE",
            description: '',
            examDuration: 120,
            passLevel: 70,
            maxAttemptCount: 0,
            examLocation: '',
            examLocationRoom: '',
            examLocationSeat: '',
          };


          this.$emit('refetch-data')
          coursedata.deleted = true;



        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },
    statusExam(coursedata, status) {
      //console.log(coursedata);
      coursedata.examStatus = status;



      store.dispatch('apps-exam/statusExam', coursedata)
        .then((response) => {

          var result = response.data.success;


          this.selectedCategory = null;
          this.localExam = {
            id: null,
            hasExactOccurDate: false,
            title: '',
            examType: "ONLINE",
            description: '',
            examDuration: 120,
            passLevel: 70,
            maxAttemptCount: 0,
            examLocation: '',
            examLocationRoom: '',
            examLocationSeat: '',
          };


          this.$emit('refetch-data')




        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },

  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-exam'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const getStatus = status => {



      // DRAFT 
      if (status == '0') return 'secondary'
      // published
      if (status == '100') return 'success'

      // ended by time limit
      if (status == '200') return 'warning'
      // stopped 
      if (status == '500') return 'warning'

      return 'secondary';


    }
    const getStatusText = status => {



      // DRAFT 
      if (status == '0') return 'Draft'
      // published
      if (status == '100') return 'Published'

      // ended by time limit
      if (status == '200') return 'Ended'
      // stopped 
      if (status == '500') return 'Stopped'

      return 'Draft';


    }


    const {
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      items,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      items,
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      getStatus,
      getStatusText
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
